import React, { useEffect, useLayoutEffect } from 'react';

import { darken, alpha } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { MapProvider } from 'react-map-gl/maplibre';
import bbox from '@turf/bbox';
import mask from '@turf/mask';
import center from '@turf/center';
import buffer from '@turf/buffer';

import './scss/components/alert.scss';
import './scss/components/button-list.scss';
import './scss/components/button.scss';
import './scss/components/chipicon.scss';
import './scss/components/list-select.scss';
import './scss/components/map-nav.scss';
import './scss/components/map-view.scss';
import './scss/components/progress.scss';
import './scss/components/rank.scss';
import './scss/components/stepper.scss';
import './scss/components/heading.scss';
import './scss/components/details.scss';
import './scss/components/logos.scss';

import './scss/_fonts.scss';
import './scss/_colors.scss';
import './scss/_forms.scss';
import './scss/_front-lg.scss';
import './scss/_front-md.scss';
import './scss/_front-sm.scss';
import './scss/_front-xs.scss';
import './scss/_global.scss';
import './scss/_utilities.scss';
import './scss/front.scss';
import './scss/layouts/main-layout.scss'; 
import './scss/layouts/survey-layout.scss'; 

import { MapContainer } from './containers/MapContainer';
import { InitContainer } from './containers/InitContainer';
import { FuncButtonContainer } from './containers/FuncButtonContainer';
import { SearchContainer } from './containers/SearchContainer';
import { InfoPoiSelectedContainer } from './containers/InfoPoiSelectedContainer';
import { SummarySurveyContainer } from './containers/SummarySurveyContainer';
import { SurveyContainer } from './components/SurveyContainer';
import { resetUser, setUserAge, setUserConsent, setUserConsentAge, setUserConsentEmail, setUserConsentHandicap, setUserEmail, setUserGender, setUserHandicap, setUserId, setUserSurvey } from './slices/userSlice';
import { getUserData, getUserSurvey } from './api/userApi';
import { convertGenderToValue } from './utils/genderUtils';
import { convertHandicapToValue } from './utils/handicapUtils';
import { notShowAttribut, setCity, setIntroduction, setPrimaryColor, setRefetch, showAttribut } from './slices/initSlice';
import { MenuModalContainer } from './containers/MenuModalContainer';
import { getConfigData } from './api/configApi';
import { setCenter, setLimitBoundings, setPolygonsCity, setTileLayer, setTileServ } from './slices/mapSlice';
import { getNeighborOfCity } from './api/zoneApi';
import { setZonesOfCity } from './slices/mapSlice';
import { setSurveyWithComment } from './slices/surveySlice';
import { config } from './config';
import { LogoMarchable } from './components/LogoMarchable';

const tileserv_url = config.url.TILE_SERVER_URL;

function App() {

  const dispatch = useDispatch();

  const initShow = useSelector(state => state.init.open);
  const searchShow = useSelector(state => state.search.open);
  const poiShow = useSelector(state => state.poi.open);
  const surveyShow = useSelector(state => state.survey.open);
  const modalInfoMenu = useSelector(state => state.menu.modalOpen);
  const refetchUser = useSelector(state => state.init.refetch);
  const attributs = useSelector(state => state.init.attributs);

  const queryParams = new URLSearchParams(window.location.search);
  const userIdFromUrl = queryParams.get('uuid');

  const fetchDataUser = (userId) => {
    getUserData(userId).then(user => {
      if (user) {
        const {uuid, age, handicap, gender, email, consent, email_consent, handicap_consent, age_consent} = user;
        if (uuid && uuid !== null) {
          dispatch(setUserId(uuid));
          dispatch(setUserEmail(email));
          dispatch(setUserAge(age));
          dispatch(setUserHandicap(convertHandicapToValue(handicap)));
          dispatch(setUserGender(convertGenderToValue(gender)));
          dispatch(setUserConsent(consent));
          dispatch(setUserConsentEmail(email_consent));
          dispatch(setUserConsentHandicap(handicap_consent));
          dispatch(setUserConsentAge(age_consent));
        } else {
          localStorage.removeItem('sondage_uuid');
          dispatch(resetUser());
        }
      } else {
        return;
      }
    });
  };

  const fetchSurveyUser = (userId) => {
    getUserSurvey(userId).then(surveys => {
      if(surveys) {
        dispatch(setUserSurvey(surveys));
      }
    });
  };

  const fetchConfigData = () => {
    getConfigData().then(response => {
      const defaultColor = '#CC4533';
      const {primary_color, zone_polygon, comment_activated, city_name, intro_text} = response;
      dispatch(setCity(city_name));
      dispatch(setIntroduction(intro_text));
      if (tileserv_url) {
        const layerTiles = tileserv_url.slice(tileserv_url.lastIndexOf('/') + 1);
        dispatch(setTileLayer(layerTiles));
        dispatch(setTileServ(tileserv_url));
        dispatch(setSurveyWithComment(false));
      }
      if (zone_polygon) {
        const geojson_polygon = {
          'type': 'FeatureCollection',
          'crs': { 'type': 'name', 'properties': { 'name': 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
          'features': [
            { 'type': 'Feature', 'properties': { }, 'geometry': JSON.parse(zone_polygon)}
          ]
        };
        const limitBoundsGeo = buffer((geojson_polygon), 2000, {units: 'meters'});
        const limitBoundings = bbox(limitBoundsGeo);
        const centerCity = center(geojson_polygon);
        const polyMask = mask(geojson_polygon);
        dispatch(setLimitBoundings(limitBoundings));
        dispatch(setCenter(centerCity));
        dispatch(setPolygonsCity(polyMask));
      }
      if (typeof comment_activated == 'boolean') {
        dispatch(setSurveyWithComment(comment_activated));
      } else {
        dispatch(setSurveyWithComment(true));
      }
      if (primary_color) {
        if (primary_color.match(/^#[a-f0-9]{6}$/i) !== null) {
          document.documentElement.style.setProperty('--primary', primary_color);
          document.documentElement.style.setProperty('--primary-dark', darken(primary_color, 0.1));
          document.documentElement.style.setProperty('--primary-shadow', alpha(primary_color, 0.3));
          document.documentElement.style.setProperty('--primary-shadow-l', alpha(primary_color, 0.2));
          dispatch(setPrimaryColor(primary_color));
        } else {
          document.documentElement.style.setProperty('--primary', defaultColor);
          document.documentElement.style.setProperty('--primary-dark', darken(defaultColor, 0.1));
          document.documentElement.style.setProperty('--primary-shadow', alpha(defaultColor, 0.3));
          document.documentElement.style.setProperty('--primary-shadow-l', alpha(defaultColor, 0.2));
          dispatch(setPrimaryColor(defaultColor));
        }
      } else {
        document.documentElement.style.setProperty('--primary', defaultColor);
        document.documentElement.style.setProperty('--primary-dark', darken(defaultColor, 0.1));
        document.documentElement.style.setProperty('--primary-shadow', alpha(defaultColor, 0.3));
        document.documentElement.style.setProperty('--primary-shadow-l', alpha(defaultColor, 0.2));
        dispatch(setPrimaryColor(defaultColor));
      }
      Object.keys(attributs).forEach(elem => {
        if (response[elem] == true) {
          dispatch(showAttribut(elem));
        } else {
          dispatch(notShowAttribut(elem));
        }
      });
    });
  };

  const fetchNeighborOfCity = () => {
    getNeighborOfCity().then(response => {
      if (response && Array.isArray(response)) {
        const listNeighborCity = response.map(elem => {
          return {
            name: elem.name,
            label: elem.label,
            bbox: JSON.parse(elem.bbox)
          };
        });
        dispatch(setZonesOfCity(listNeighborCity));
      } else {
        return;
      }
    });
  };

  useLayoutEffect(() => {
    fetchConfigData();
    fetchNeighborOfCity();
  }, []);

  useEffect(() => {
    if ( userIdFromUrl !== null ) {
      localStorage.setItem('sondage_uuid', userIdFromUrl);
    }
  }, []);

  useEffect(() => {
    const userId = localStorage.getItem('sondage_uuid');
    if(userId !== undefined && userId !== null) {
      fetchDataUser(userId);
      fetchSurveyUser(userId);
    }
  }, [localStorage]);

  useEffect(() => {
    const userId = localStorage.getItem('sondage_uuid');

    if (refetchUser && userId !== null) {
      fetchDataUser(userId);
      fetchSurveyUser(userId);
      dispatch(setRefetch(false));
    }
  }, [refetchUser]);


  return (
    <MapProvider>
      <main id="app" className={(surveyShow || modalInfoMenu) ? 'survey-screen overlay-active' : 'overlay-active'}>
        <MapContainer />
        {(!initShow && !surveyShow && !modalInfoMenu) && (
          <LogoMarchable />
        )}
        <InitContainer initShow={initShow} />
        {(!initShow && !surveyShow && !modalInfoMenu) && (
          <FuncButtonContainer />
        )}
        {searchShow && (
          <SearchContainer />
        )}
        {poiShow && (
          <InfoPoiSelectedContainer />
        )}
        <SurveyContainer surveyShow={surveyShow} />
        <SummarySurveyContainer />
        <MenuModalContainer />
      </main>
    </MapProvider>
  );
}

export default App;
