import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { GeocoderInput } from '../components/GeocoderInput';
import { SecondaryButton } from '../components/Button/SecondaryButton';
import { closeInfoPoi, resetPoi, setTypePoiSelected } from '../slices/poiSlice';
import { openSurvey } from '../slices/surveySlice';
import { hideOptionsList, openSearch, showOptionsList } from '../slices/searchSlice';
import { setAddStreet, startResetSelected } from '../slices/mapSlice';
import { StreetView } from '../components/StreetView';

export const InfoPoiSelectedContainer = () => {

  const labelSelected = useSelector(state => state.poi.labelPoi);
  const typePoiSelected = useSelector(state => state.poi.typePoiSelected);

  const dispatch = useDispatch();

  const handlePoiSurvey = () => {
    dispatch(closeInfoPoi());
    dispatch(openSurvey());
  };

  const returnToSearchWithOutOptions = () => {
    dispatch(startResetSelected());
    dispatch(resetPoi());
    dispatch(hideOptionsList());
    dispatch(openSearch());
  };

  const returnToSearchWithOptions = () => {
    dispatch(startResetSelected());
    dispatch(closeInfoPoi());
    dispatch(showOptionsList());
    dispatch(openSearch());
  };


  const handleSetAllStreet = () => {
    dispatch(setAddStreet(true));
    dispatch(closeInfoPoi());
    dispatch(setTypePoiSelected('street'));
  };

  return (
    <div className="overlay flow">
      <div className="flex align-stretch gap-1">
        <button className="btn btn-empty btn-icon btn-icon-lg squeeze" onClick={returnToSearchWithOutOptions}>
          <span className="icon-caret-left"></span>
        </button>
        <div className="grow">
          <div className="form-group">
            <GeocoderInput 
              value={labelSelected}
              handlePressInput={returnToSearchWithOptions}
            />
          </div>
        </div>
      </div>
      <figure className="map-view square">
        <StreetView />
        <div className="map-actions">
          <button className="btn btn-white btn-icon" onClick={() => console.log('button clicked')}>
            <span className="icon icon-zone"></span>
          </button>
        </div>
      </figure>
      <SecondaryButton label='Noter ce tronçon de rue/chemin' handleClick={handlePoiSurvey} />
      {typePoiSelected === 'portion' && (
        <SecondaryButton label='Noter toute la rue/chemin' handleClick={handleSetAllStreet} />
      )}
    </div>
  );
};