import React from 'react';
import { ForwardButton } from '../Button/ForwardButton';

export const SendLink = () => {
  return (
    <div className="form-input">
      <label className='form-label'>
        Saisissez l&apos;adresse e-mail pour recevoir votre lien unique
      </label>
      <input 
        style={{
          width: '100%', 
          height: 40, 
          borderRadius: '.5em', 
          marginTop: '1em', 
          marginBottom: '1em',
          paddingLeft: '.5em'
        }}
        className="form-text" 
        type="email" 
        name="email-return-link" 
        id="email-return-link"                
      />
      <footer className='survey-actions grid xs:grid-col-1 md:grid-col-12'>
        <div className='md:order-1 md:col-start-5 md:col-end-8'>
          <ForwardButton 
            label='Récupérer' 
            handleClick={() => console.log('récupérer iden')}
          />
        </div>
      </footer>
    </div>
  );
};