import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { closeInfoUser, showInfoUser } from '../slices/userSlice';
import { MenuContainer } from './MenuContainer';
import { closeMenu, openMenu } from '../slices/menuSlice';
import { UserInfoContainer } from './UserInfoContainer';
import { MapControl } from '../components/MapControl';

export const FuncButtonContainer = () => {
  const dispatch = useDispatch();
  const openMenuFunc = useSelector(state => state.menu.open);
  const openInfoUser = useSelector(state => state.user.show);
  const userId = useSelector(state => state.user.uuid);

  const handleOpenUserInfo = () => {
    if (openMenu) {
      dispatch(closeMenu());
    }
    dispatch(showInfoUser());
  };

  const handleCloseUserInfo = () => {
    dispatch(closeInfoUser());
  };

  const handleOpenMenu = () => {
    if (openInfoUser) {
      dispatch(closeInfoUser());
    }
    dispatch(openMenu());
  };

  const handleCloseMenu = () => {
    dispatch(closeMenu());
  };

  return (
    <nav className="map-nav" style={{'--overlay-offset': '20vh'}}>
      <div>
        <button className="btn btn-white btn-icon shadow-md" onClick={handleOpenMenu}>
          <span className="icon icon-burger"></span>
        </button>
        {userId !== null && (
          <button className="btn btn-white btn-icon shadow-md" onClick={handleOpenUserInfo}>
            <span className="icon icon-user-star"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
          </button>
        )}
      </div>
      <MenuContainer open={openMenuFunc} onClose={handleCloseMenu} />
      <MapControl />
      {userId !== null && (
        <UserInfoContainer open={openInfoUser} onClose={handleCloseUserInfo} />
      )}
    </nav>
  );
};