import React, { createRef, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Viewer } from 'mapillary-js';

import buffer from '@turf/buffer';
import bbox from '@turf/bbox';

import { getImageStreetId } from '../api/streetviewApi';

const mapillary_access_token = 'MLY|6775241895922391|0d8bb0fe1626b0804a775875b9f30518';

export const StreetView = () => {

  const boundsView = useSelector(state => state.poi.bounds);

  const [idView, setIdView] = useState(undefined);

  const streetViewRef = createRef();

  useEffect(() => {
    if (boundsView !== undefined && boundsView !== null) {
      const buffered = buffer(boundsView, 10, {units: 'meters'});
      const boundsSearchView = bbox(buffered);
      getImageStreetId(boundsSearchView).then(result => {
        if (result && result.data?.length > 0) {
          setIdView(result.data[0].id);
        } else {
          setIdView(undefined);
        }
      });
    }
  }, [boundsView]);


  useEffect(() => {
    const viewer = new Viewer({
      accessToken: mapillary_access_token,
      container: streetViewRef.current,
      imageId: idView,
      component: {
        cover: true
      }
    });
    return () => {
      viewer.remove();
    };
  }, [idView]);

  return (
    <div ref={streetViewRef} style={{height: 360, width: 360}} />
  ); 
  
};