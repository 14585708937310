import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  labelPoi: '',
  bounds: null,
  typePoiSelected: 'street',
};

const poiSlice = createSlice({
  name: 'poi',
  initialState,
  reducers: {
    openInfoPoi: (state) => {
      state.open = true;
    },
    closeInfoPoi: (state) => {
      state.open = false;
    },
    setLabelPoi: (state, action) => {
      state.labelPoi = action.payload;
    },
    setBoundsPoi: (state, action) => {
      state.bounds = action.payload;
    },
    setTypePoiSelected: (state, action) => {
      state.typePoiSelected = action.payload;
    },
    resetPoi: () => initialState
  }
});

export const { 
  setLabelPoi, 
  setTypePoiSelected, 
  openInfoPoi, 
  setBoundsPoi,
  closeInfoPoi, 
  resetPoi 
} = poiSlice.actions;

export default poiSlice.reducer;