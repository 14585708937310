export const getLabelCriteria = criteria => {
  switch (criteria) {
    case 'practicability':
      return 'Praticabilité';
    case 'comfort':
      return 'Confort';
    case 'security':
      return 'Sécurité';
    default:
      return 'Inconnu';
  }
};

export const getLabelMenu = type => {
  switch (type) {
    case 'presentation':
      return 'Présentation';
    case 'link':
      return "J'ai perdu mon lien unique";
    case 'contact':
      return 'Nous contacter';
    case 'condition':
      return "Conditions Générals d'Utilisation";
    case 'legal':
      return 'Mentions légales';
    default:
      return;
  }
};