import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  city: '',
  introduction: '',
  open: true,
  step: 'welcome',
  refetch: false,
  primary_color: '#000000',
  attributs: {
    accessibility: true,
    aesthetic: true,
    bikes: true,
    cars: true,
    cleanness: true,
    crossings: true,
    crowded: true,
    lighting: true,
    noise: true,
    obstruction: true,
    safety: true,
    seat: true,
    shaded: true,
    shops: true,
    sidewalks: true,
    slope: true,
    stops: true,
    vegetation: true,
  }
};

const initSlice = createSlice({
  name: 'init',
  initialState,
  reducers: {
    showWelcome: (state) => {
      state.open = true;
    },
    closeWelcome: (state) => {
      state.open = false;
    },
    setPrimaryColor: (state, action) => {
      state.primary_color = action.payload;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload;
    },
    setIntroduction: (state, action) => {
      state.introduction = action.payload;
    },
    setRefetch: (state, action) => {
      state.refetch = action.payload;
    },
    showAttribut: (state, action) => {
      state.attributs = {...state.attributs, [action.payload]: true};
    },
    notShowAttribut: (state, action) => {
      state.attributs = {...state.attributs, [action.payload]: false};
    }
  }
});

export const { 
  showWelcome, 
  closeWelcome, 
  setPrimaryColor,
  setStep, 
  setIntroduction,
  setRefetch,
  setCity,
  showAttribut,
  notShowAttribut
} = initSlice.actions;

export default initSlice.reducer;