import React, {useEffect, useState} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form'; 

import { CommentContainer } from './Evaluation/CommentContainer';
import { IconChip } from '../components/IconChip';
import { HeaderSurvey } from '../components/HeaderSurvey';
import { StepperSurvey } from '../components/StepperSurvey';
import { listCriteria } from '../data/criteria';
import { CriteriaCard } from '../components/Criteria/CriteriaCard';
import { closeSurvey, resetSurvey, setStepSurvey } from '../slices/surveySlice';
import { createSurvey, updateSurvey } from '../api/surveyApi';
import { startResetSelected } from '../slices/mapSlice';
import { openSummary } from '../slices/summarySlice';
import { setRefetch } from '../slices/initSlice';

export const FormSurvey = ({stepEvaluation, steps, surveyResults}) => {
  const dispatch = useDispatch();
  const {register, watch , handleSubmit, setValue} = useForm({defaultValues: surveyResults});

  const criteriaShow = useSelector(state => state.init.attributs);

  const userId = useSelector(state => state.user.uuid);
  const userEmail = useSelector(state => state.user.email);

  const typeSurvey = useSelector(state => state.survey.type);
  const surveyId = useSelector(state => state.survey.surveyId);
  const labelSelected = useSelector(state => state.poi.labelPoi);

  const [listCriteriaShown, setListCriteriaShown] = useState(listCriteria);

  const renderHeader = () => {
    switch (stepEvaluation) {
      case 'practicability':
        return 'Est-ce que cette rue/chemin est praticable ?';
      case 'comfort':
        return 'Est-ce que la marche dans cette rue/chemin est agréable ?';
      case 'security':
        return 'Quel est votre sentiment de sécurité dans cette rue/chemin ?';
      case 'comment':
        return 'Avez vous une remarque générale ou une information complémentaire à apporter sur cette rue/chemin ?';
      default:
        return 'Que pensez-vous de cette rue/chemin ?';
    }
  };

  const handleCriteriaPos = (id) => {
    setValue(id, true);
  };

  const handleCriteriaNev = (id) => {
    setValue(id, false);
  };

  const handleRemoveCriteria = (id) => {
    setValue(id, null);
  };

  const handleFinishSurvey = () => {
    dispatch(closeSurvey());
    dispatch(setRefetch(true));
    dispatch(startResetSelected());
    dispatch(resetSurvey());
  };


  const handleMoveToSummary = () => {
    handleFinishSurvey();
    dispatch(openSummary());
  };

  const handleMoveToUserInfo = () => {
    dispatch(setStepSurvey('userInfo'));
  };

  const handleCreateSurvey = (data) => {
    if(userId !== null) {
      createSurvey({...data, owner_uuid: userId}).then(() => {
        if (userEmail === null) {
          handleMoveToUserInfo();
        } else {
          handleMoveToSummary();
        }
      });
    } else {
      createSurvey(data).then(() => {
        dispatch(setRefetch(true));
        handleMoveToUserInfo();
      });
    }
  };

  const handleUpdateSurvey = (data) => {
    if (userId !== null && surveyId !== null) {
      updateSurvey(userId, surveyId, data).then(() => {
        if (userEmail === null) {
          dispatch(setStepSurvey('userInfo'));
        } else {
          handleMoveToSummary();
        }
      });
    }
  };

  const onSubmit = (data) => {
    if (typeSurvey === 'update') {
      handleUpdateSurvey(data);
    } else {
      handleCreateSurvey(data);
    }
  };

  useEffect(() => {
    const listResults = listCriteria.map(elem => {
      return {...elem, show: criteriaShow[elem.id]};
    });
    setListCriteriaShown(listResults);
  }, []);


  return (
    <div className='survey-body-flow'>
      <IconChip step={stepEvaluation}/>
      <HeaderSurvey label={renderHeader(labelSelected)}/>
      <form id='survey-form' onSubmit={handleSubmit(onSubmit)}>
        {stepEvaluation !== 'comment' ? (
          <>
            <p>Vous pouvez noter seulement les critères qui vous semblent pertinents.</p>
            <StepperSurvey 
              steps={steps} 
              currentStep={stepEvaluation}
              
            />
            <ul className='list-select grid grid-col-1 md:grid-col-2 md:gap-2 has-buttons'>
              {listCriteriaShown.filter(elem => elem.theme === stepEvaluation).map(elem => {
                if (elem.show) {
                  return (
                    <li key={elem.id}>
                      <CriteriaCard 
                        id={elem.id}
                        label={elem.label} 
                        details={elem.details} 
                        handlePos={() => handleCriteriaPos(elem.id)}
                        handleNev={() => handleCriteriaNev(elem.id)}
                        handleReset={() => handleRemoveCriteria(elem.id)}
                        value={watch(elem.id)} 
                      />
                    </li>
                  );
                }
              })}
            </ul>
          </>
        ): (
          <CommentContainer 
            register={register}
          />
        ) }
      </form>
    </div>
  );
};