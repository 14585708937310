import React from 'react';

import Modal from '@mui/material/Modal';


import { useResponsive } from '../hooks/useResponsive';
import { useSelector } from 'react-redux';
import { InfoModalContainer } from '../components/Modal/InfoModal';
import { useModal } from '../hooks/useModal';

export const MenuModalContainer = () => {

  const { isMobile } = useResponsive();

  const style = {
    modalStyle: {
      display: 'flex',
      width: '70%',
      alignItems: 'center',
      justifyContent: 'center',
      top: 'unset',
      bottom: 'unset',
      left: 'unset',
      right: 'unset'
    }
  };

  const {handleCloseModalMenu} = useModal();

  const openModal = useSelector(state => state.menu.modalOpen);

  return (
    <React.Fragment>
      {isMobile ? (
        <React.Fragment>
          {openModal && (
            <InfoModalContainer />
          )}
        </React.Fragment>
      ) : (
        <Modal 
          open={openModal} 
          sx={style.modalStyle}
          onClose={handleCloseModalMenu}
          disablePortal
        >
          <React.Fragment>
            <InfoModalContainer />
          </React.Fragment>
        </Modal>
      )}
    </React.Fragment>
  );
};