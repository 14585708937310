import React from 'react';

export const Contact = () => {
  return (
    <div className='contact-container'>
      <div className='contact-section'>
        <span className='chipicon-contact icon-pin'></span>
        <div className='label-container'>
          <h3>Someware</h3>
          <span>4c Avenue de Peupliers</span>
          <span>35510 Cesson-Sévigné</span>
        </div>
      </div>
      <div className='contact-section'>
        <span className='chipicon-contact icon-info'></span>
        <span>contact@someware.fr</span>
      </div>
      <div className='contact-section'>
        <span className='chipicon-contact icon-link'></span>
        <a href='https://www.someware.fr/' target='_blank' rel="noreferrer">
          https://www.someware.fr
        </a>
      </div>
    </div>
  );
};