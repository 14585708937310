import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useMap } from 'react-map-gl/maplibre';
import bbox from '@turf/bbox';

import { ForwardButton } from '../components/Button/ForwardButton';
import { SecondaryButtonWithIcon } from '../components/Button/SecondaryButtonWithIcon';
import { useGeoLocation } from '../hooks/useGeoLocation';
import { setZonesOfCity } from '../slices/mapSlice';
import { useModal } from '../hooks/useModal';


export const NeighborhoodSelectContainer = () => {
  const dispatch = useDispatch();
  const { map } = useMap();

  const { handleGeolocation } = useGeoLocation();
  const { handleCloseModalSelectNeighbor } = useModal();

  const cityNeighborhood = useSelector(state => state.map.zones);

  const handleSelectNeighbor = value => {
    const bounds = bbox(value);
    if (map && bounds && bounds.length > 0) {
      map.fitBounds(bounds);
      handleCloseModalSelectNeighbor();
      dispatch(setZonesOfCity([]));
    }
  };

  return (
    <>
      <h2 className="heading">Quel est le quartier concerné ?</h2>
      <ul className="list-select h-40 has-buttons has-colors flow-half">
        {cityNeighborhood.map(elem => (
          <li key={elem.name}>
            <button onClick={() => handleSelectNeighbor(elem.bbox)}>
              {elem.label}
            </button>
          </li>
        ))}
      </ul>
      <SecondaryButtonWithIcon 
        icon='geoloc'
        label='Je ne sais pas, je me localise' 
        handleClick={handleGeolocation}
      />
      <ForwardButton 
        label='Continuer' 
        handleClick={handleCloseModalSelectNeighbor}
      />
    </>
  );
};