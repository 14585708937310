import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getRankBySurveys, surveyRestFromChange } from '../utils/rankUtils';
import { openSurvey, setStepSurvey } from '../slices/surveySlice';
import { closeInfoUser, setModify } from '../slices/userSlice';
import { closeInfoPoi } from '../slices/poiSlice';
import { startResetSelected } from '../slices/mapSlice';

export const InfoSurveyByUser = ({showModify = false}) => {

  const userSurveysList = useSelector(state => state.user.surveyAnswers);
  const showPoi = useSelector(state => state.poi.open);
  const dispatch = useDispatch();
  
  const [numberSurveyRested, setNumberSurveyRested] = useState(0);
  const [percentageRested, setPercentageRested] = useState(0);

  const handleOpenModifyUser = () => {
    if (showPoi) {
      dispatch(closeInfoPoi());
      dispatch(startResetSelected());
    }
    dispatch(closeInfoUser());
    dispatch(setModify(true));
    dispatch(setStepSurvey('userInfo'));
    dispatch(openSurvey());
  };

  useEffect(() => {
    const numberSurveyRest = surveyRestFromChange(userSurveysList.length);
    setNumberSurveyRested(numberSurveyRest);
    setPercentageRested((numberSurveyRest * 100) / 5);
    return () => {
      setNumberSurveyRested(0);
      setPercentageRested(0);
    };
  }, [userSurveysList]);
  
  return (
    <>
      <div className={`rank level-${getRankBySurveys(userSurveysList.length)}`}>
        Contributeur
      </div>
      <div className="heading">Déjà <span className="txt-primary">{userSurveysList.length} rue(s)</span> évaluée(s) !</div>
      <div className="progress" id="to-next-rank" style={{'--missing': `${percentageRested}%`}}></div>
      {showModify && (
        <div className='user-modify'>
          <button className='btn-update' onClick={handleOpenModifyUser}>Modifier mes données personnelles</button>
        </div>
      )}
      <p>
        Donnez votre avis sur <strong>{numberSurveyRested} rue(s) supplémentaire(s)</strong> pour devenir :
      </p>
      <div className={`rank level-${getRankBySurveys(userSurveysList.length) + 1}`}>
        Contributeur
      </div>
    </>
  );
};